import { template } from '@shein/common-function'
import { percentNum, percentNumWithoutSign } from 'public/src/pages/common/promotion/utils/common.js'

export function getPromoDiffInfo(promo, language = {}) {
  const { typeId, rules } = promo
  const { range, diff, hit_range, next } = promo?.promotion_range_info || {}
  let result = {
    id: promo.promotion_id,
  }
  if(isWithinNext24Hours(Number(promo.end_time))) {
    result.end_time = Number(promo.end_time)
  }
  if(typeId == 1) { // 无限档位
    let { discount = {}, value = ''} = rules[0] || {}
    result.tipText = template(
      value,
      percentNumWithoutSign(discount.value),
      language.SHEIN_KEY_PWA_33548
    )
    result.diff = {
      value: diff?.value,
      type: 'number' // 件数差额
    }
    result.iconName = 'sui_icon_activity_18px'
  } else if (typeId == 9) { // 买N折N， 件数
    if(range <= 0 || next != '0') { // 未满足或部分满足
      let nextRule = rules[range < 0 ? 0 : range]
      result.tipText = template(
        nextRule.value,
        ((1 - nextRule.discount.value) * 100).toFixed(0),
        nextRule.discount?.type == '3' ? language.SHEIN_KEY_PWA_33550 : language.SHEIN_KEY_PWA_33549
      )
      result.diff = {
        value: diff?.value,
        type: 'number'
      }
      result.iconName = 'sui_icon_activity_18px'
    }
  } else if (typeId == 14) { // 全场满减与非全场满减文案和坑位取值逻辑类似
    const isEveryFull = rules[0].type == 2 || rules[0].type == 4 // 是否每满
    const isFullPromo = promo?.goods?.type == 3 // 是否为全场满减
    if(range <= 0 || next != 0) {
      let nextRule = isEveryFull ? rules[0] : rules[range < 0 ? 0 : range]
      if((nextRule?.type == 3 || nextRule?.type == 4) && nextRule?.discount?.type == 6) { // 满元或每满元
        result.tipText = template(
          nextRule.discount?.value_amount?.amountWithSymbol,
          nextRule.value_amount?.amountWithSymbol,
          language.SHEIN_KEY_PWA_33553
        )
        result.diff = {
          value: diff?.value_amount?.amount,
          type: 'amount'
        }
        result.iconName = isFullPromo ? 'sui_icon_coupon_discount_18px' : 'sui_icon_activity_18px'
      } else if((nextRule.type ==1 || nextRule.type == 2) && nextRule.discount?.type == 6) { // 满件或每满件
        result.tipText = template(
          nextRule.discount?.value_amount?.amountWithSymbol,
          nextRule.value,
          language.SHEIN_KEY_PWA_33552
        )
        result.diff = {
          value: diff?.value,
          type: 'number'
        }
        result.iconName = isFullPromo ? 'sui_icon_coupon_discount_18px' : 'sui_icon_activity_18px'
      }
    }
  } else if (typeId == 15) {
    const type = rules[0]?.type // type == 2 每满
    const nextRule = type == 2 ? rules[0] : rules[range]
    if(type == 2) {
      result.tipText = template(
        nextRule.discount?.value_amount?.amountWithSymbol,
        nextRule.value,
        language.SHEIN_KEY_PWA_33555
      )
      result.diff = {
        value: diff?.value,
        type: 'number'
      }
      result.iconName = 'sui_icon_activity_18px'
    } else { // 满X件
      if(range <= 0 || next != '0') {
        result.tipText = template(
          nextRule.discount?.value_amount?.amountWithSymbol,
          nextRule.value,
          language.SHEIN_KEY_PWA_33554
        )
        result.diff = {
          value: diff?.value,
          type: 'number'
        }
        result.iconName = 'sui_icon_activity_18px'
      }
    }
  } else if (typeId == 25 || typeId == 26) {
    if(range <= 0 || next != '0') {
      const nextRule = rules[range < 0 ? 0 : range]
      result.tipText = template(
        diff?.value_amount.amountWithSymbol,
        percentNumWithoutSign(nextRule.discount?.value),
        language.SHEIN_KEY_PWA_33551
      )
      result.diff = {
        value: diff?.value_amount.amount,
        type: 'amount'
      }
      result.iconName = typeId == 25 ? 'sui_icon_activity_18px' : 'sui_icon_coupon_discount_18px'
    }
  } else {
    return {}
  }
  return result
}

export function getCouponDiffInfo(couponInfo, language) {
  let AddCoupons = []
  for(const coupon of (couponInfo?.usableCouponList || [])) { // 查找部分满足的券
    // 判断为完全满足，则不纳入凑单数据
    // 参考自public/src/pages/cartNew/components/coupon/_CartCouponHelper.vue 中getShowAddonBtn方法
    const satisfiedRange = Number(coupon.satisfied_range)
    if(satisfiedRange >= coupon?.other_coupon_rule?.length || ['5', '6', '9'].includes(coupon?.apply_for) || coupon?.reason == 8) continue
    const nextRule = coupon?.other_coupon_rule?.[satisfiedRange] || {}
    const diff = Number(nextRule.need_price?.amount)
    const value = coupon?.coupon_type_id == '1' ? nextRule.price.priceSymbol : (Number(nextRule.price.price || 0) * 100 / 100) + '%'
    AddCoupons.push({
      code: coupon.coupon,
      apply_for: coupon.apply_for,
      tipText: template(
        diff,
        value,
        language.SHEIN_KEY_PWA_33556
      ),
      diff,
      value,
      goodsList: coupon.cart_coupon_goods_lists,
      iconName: 'sui_icon_coupon_discount_18px',
      end_time: isWithinNext24Hours(Number(coupon.end_date)) ? Number(coupon.end_date) : 0,
    })
  }
  for(const coupon of (couponInfo?.disabledCouponList || [])) {
    if(['5', '6', '9'].includes(coupon?.apply_for)) continue
    const nextRule = coupon?.other_coupon_rule?.[0] || {}
    const diff = Number(coupon.needPrice?.amount)
    const value = coupon?.coupon_type_id == '1' ? nextRule.price.priceSymbol : (Number(nextRule.price.price || 0) * 100 / 100) + '%'
    AddCoupons.push({
      code: coupon.coupon,
      apply_for: coupon.apply_for,
      tipText: template(
        coupon.needPrice?.amountWithSymbol,
        value,
        language.SHEIN_KEY_PWA_33556
      ),
      diff,
      value,
      goodsList: coupon.cart_coupon_goods_lists,
      iconName: 'sui_icon_coupon_discount_18px',
      end_time: isWithinNext24Hours(Number(coupon.end_date)) ? Number(coupon.end_date) : 0,
    })
  }
  return AddCoupons
}

export function getAddonTips(item = {}, AllPromotionDetials = {}, couponInfo = {}, language = {}) {
  let AddPromos = []
  const promoTypes = [1, 9, 14, 15, 25, 26], showNums = 3
  const ItemMatchedPromotions = item?.product?.product_promotion_info?.filter(promo => promoTypes.includes(Number(promo?.type_id))) || []
  for(const promo of ItemMatchedPromotions) {
    const promoDetail = AllPromotionDetials[promo?.promotion_id] || {}
    const diffInfo = getPromoDiffInfo(promoDetail, language)
    if(!diffInfo?.diff) continue // 该活动已满足或不在范围内
    AddPromos.push(diffInfo)
  }
  AddPromos.sort((x, y) => {
    if(x.diff?.type != y.diff?.type) {
      return x.diff?.type == 'amount' ? -1 : 1
    }
    return x.diff.value - y.diff.value
  })
  if(AddPromos.length >= showNums) { // 大于或等于三个则直接输出
    return AddPromos.slice(0, showNums)
  }
  // 当前商品已用券，则不展示券凑单相关数据
  if(item?.per_coupon_discount?.length > 0) return AddPromos
  let couponShowNumsCount = showNums - AddPromos.length
  let couponDiffInfo = getCouponDiffInfo(couponInfo, language)
  couponDiffInfo.sort((x, y) => {
    return x.diff - y.diff
  })
  for(const couponDiff of couponDiffInfo) {
    if(couponShowNumsCount > 0 && couponDiff.goodsList?.includes(item?.product?.goods_sn)){
      AddPromos.push(couponDiff)
      couponShowNumsCount--
    }
  }
  return AddPromos
}

/**
 * 判断时间戳（以秒为单位）是否在未来24小时内
 * @param {number} timestampInSeconds - 要判断的时间戳，单位是秒
 * @returns {boolean} - 如果时间戳在未来24小时内返回 true，否则返回 false
 */
function isWithinNext24Hours(timestampInSeconds) {
  // 获取当前时间的毫秒数
  const now = Date.now();

  // 将时间戳转换为毫秒
  const timestampInMillis = timestampInSeconds * 1000;

  // 计算未来24小时的时间点
  const twentyFourHoursFromNow = now + (24 * 60 * 60 * 1000);

  // 判断时间戳是否在未来24小时内
  return timestampInMillis <= twentyFourHoursFromNow && timestampInMillis > now;
}